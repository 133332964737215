<template>
  <div>
    <!-- user info -->
    <div class="card card-custom">
      <div class="card-header">
        <div class="card-title">
          <span class="card-icon">
            <i class="flaticon2-information text-primary"></i>
          </span>
          <h3 class="card-label">KYC Fineksus</h3>
        </div>
      </div>
      <div class="card-body" v-if="fineksusDataLoading">
        <div class="row col-12">
          <div class="col-4">
            <Skeleton :count="14" height="20px" />
          </div>
          <div class="col-8 font-weight-bolder">
            <Skeleton :count="14" height="20px" />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="card-body border-bottom border-primary rounded-bottom" v-if="fineksusDataSearchResults.length === 0">
            <div class="row col-12 font-weight-bolder h6 align-items-center">
                {{ $t('commons.no_data_available') }}
            </div>
        </div>
        <div class="card-body border-bottom border-primary rounded-bottom" v-for="searchRes in fineksusDataSearchResults" :key="`searchRes-${searchRes.NameIdentifier}-${searchRes.Index}`">
            <div class="row col-12 font-weight-bolder h5">
                {{ $t('user_details.kyc_fineksus.search_res') }} {{ searchRes.Index }}
            </div>
            <div class="row col-12 mb-5">
                <div class="col-4">{{ $t('user_details.kyc_fineksus.matched_name') }}: </div>
                <div class="col-8 font-weight-bolder">{{ searchRes.MatchedName }}</div>
            </div>
            <div class="row col-12 mb-5">
                <div class="col-4">{{ $t('user_details.kyc_fineksus.primary_name') }}: </div>
                <div class="col-8 font-weight-bolder">{{ searchRes.Primary_Name }}</div>
            </div>
            <div class="row col-12 mb-5">
                <div class="col-4">{{ $t('user_details.kyc_fineksus.name_matched_rate') }}: </div>
                <div class="col-8 font-weight-bolder">{{ searchRes.MatchedScore }}</div>
            </div>
            <div class="row col-12 mb-5">
                <div class="col-4">{{ $t('user_details.kyc_fineksus.gender') }}: </div>
                <div class="col-8 font-weight-bolder">{{ searchRes.Gender }}</div>
            </div>
            <div class="row col-12 mb-5">
                <div class="col-4">{{ $t('user_details.kyc_fineksus.date_of_birth') }}: </div>
                <div class="col-8 font-weight-bolder" v-html="searchRes.DateOfBirth"></div>
            </div>
            <div class="row col-12 mb-5">
                <div class="col-4">{{ $t('user_details.kyc_fineksus.place_of_birth') }}: </div>
                <div class="col-8 font-weight-bolder" v-html="searchRes.PlaceOfBirth"></div>
            </div>
            <div class="row col-12 mb-5">
                <div class="col-4">{{ $t('user_details.kyc_fineksus.note') }}: </div>
                <div class="col-8 font-weight-bolder">{{ searchRes.Note }}</div>
            </div>
            <div class="row col-12 mb-5">
                <div class="col-12 font-weight-bolder">{{ $t('user_details.kyc_fineksus.known_other_names') }}: </div>
                <div class="list-group col-12 mx-2">
                    <div
                        class="list-group-item list-group-item-action flex-column align-items-start"
                        v-for="(otherName,otherNameIdx) in searchRes.NameLists"
                        :key="`other-name-${otherNameIdx}`"
                    >
                        <p class="mb-1">{{ otherName.LastName }}</p>
                    </div>
                </div>
            </div>
            <div class="row col-12">
                <div class="col-12 font-weight-bolder">{{ $t('user_details.kyc_fineksus.descriptions') }}: </div>
                <div class="list-group col-12 mx-2">
                    <div
                        class="list-group-item list-group-item-action flex-column align-items-start"
                        v-for="(descItem,descIdx) in searchRes.Descs"
                        :key="`desc-${descIdx}`"
                    >
                        <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1"></h5>
                        <small>{{ descItem.FromDate }}</small>
                        </div>
                        <p class="mb-1">{{ descItem.Description }}</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Skeleton } from 'vue-loading-skeleton';
import "vue-multiselect/dist/vue-multiselect.min.css";
import { mapState } from 'vuex';

export default {
  name: "UserKYCFineksus",
  components: {
    Skeleton,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.customer.permissions,
      user: (state) => state.userdetails.userDetails.user,
      fineksusDataLoading: (state) => state.userdetails.userDetailsLoading,
      fineksusDataSearchResults: (state) => state.userdetails.fineksusData,
    }),
  },
  methods: {
    checkPermission(permission) {
      return this.permissions?.some(val => String(val.name) === String(permission));
    },
  },
  created() {
    if (this.permissions?.length > 0 && !this.checkPermission("crm.users.show")) {
      this.$router.push("/no-permission");
      return;
    }
    this.$store.dispatch('userdetails/fetchFineksusAction', this.user.id)
  }
};
</script>

